import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { TranslocoService } from '@jsverse/transloco'
import { take } from 'rxjs'

import { ConfirmationService, MessageService } from 'primeng/api'

import { BackendService, EntityInterfaces } from '@goplanner/api-client'

/**
 * Confirmation update component
 */
@Component({
  selector: 'app-confirmation-update',
  template: ``,
  standalone: true,
  imports: []
})
export class ConfirmationUpdateComponent<T extends keyof EntityInterfaces>
  implements OnInit
{
  /**
   * The element id
   */
  id!: number

  /**
   * The entity name
   */
  entityName!: T

  /**
   * The new values
   */
  values!: Partial<EntityInterfaces[T]['put']>

  /**
   * Confirmation message
   */
  confirmMessage!: string

  /**
   * The type of the element
   */
  type: 'danger' | 'info' | 'success' = 'info'

  /**
   * The constructor
   * @param backend the backend service
   * @param message the message service
   */
  constructor(
    private backend: BackendService,
    private message: MessageService,
    private translate: TranslocoService,
    private confimation: ConfirmationService
  ) {}

  /**
   * The component initialization
   */
  ngOnInit(): void {
    this.confimation.confirm({
      header: this.translate.translate('goplanner.COMMON.CONFIRMATION'),
      message: this.translate.translate(this.confirmMessage),
      acceptButtonStyleClass: `p-button-${this.type}`,
      rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
      acceptLabel: this.translate.translate(
        'goplanner.COMMON.CONFIRMATION_ACCEPT'
      ),
      rejectLabel: this.translate.translate(
        'goplanner.COMMON.EXECUTE_FUNCTION_REJECT'
      ),
      accept: () => {
        this.backend
          .put(this.entityName, {
            ...this.values,
            id: this.id
          })
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.message.add({
                severity: 'success',
                summary: this.translate.translate('goplanner.COMMON.UPDATED'),
                detail: this.translate.translate(
                  'goplanner.COMMON.UPDATE_REGISTRY_SUCCESS'
                )
              })
              this.afterUpdate.emit(res as EntityInterfaces[T]['get'])
              this.destroy.emit()
            },
            error: (error) => {
              this.message.add({
                severity: 'error',
                summary: this.translate.translate('goplanner.COMMON.ERROR'),
                life: 5000,
                detail:
                  error.error ??
                  this.translate.translate(
                    'goplanner.COMMON.UPDATE_REGISTRY_ERROR'
                  )
              })
              this.destroy.emit()
            }
          })
      },
      reject: () => {
        this.destroy.emit()
      }
    })
  }

  @Output()
  afterUpdate = new EventEmitter<EntityInterfaces[T]['get']>()

  /**
   * The destroy event
   */
  @Output()
  destroy = new EventEmitter<void>()
}
